<template>
  <div>
    <h1 class="grey--text">Registration</h1>
    <v-container class="my-5">
      <p>Registrationsformular hier</p>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Registration',
};
</script>
